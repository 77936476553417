@import '../../../variables';


#perks-container{
  padding: 1cm 0 0 0;
  text-align: center;
  overflow: hidden;


  #perks-list{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 100px;
    overflow: hidden;

    &>div{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100vw;
      padding: 20vh 5vw 20vh 15vw;
      position: relative;
      overflow: hidden;

      &:nth-of-type(1){
        z-index: 1;
      }

      &:nth-of-type(2){
        z-index: 2;
      }

      &:nth-of-type(3){
        z-index: 3;
      }

      &:nth-of-type(4){
        z-index: 4;
      }

      &:nth-of-type(5){
        z-index: 5;
      }

      //height: 400px;

      img{
        //  >img..

        position: fixed;
        top: 50%;

      }



      &>div{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;
        font-family: $robotomono;
        width: 60%;
        h1{
          font-size: 6rem;
          margin: 0;
          color: black;
        }
        p{
          font-size: 1.5rem;
          margin: 0;
          color: black;
          font-weight: bold;
        }
      }
    }
  }
}