@import '../../variables';

@mixin transition($ms){
  transition: $ms;
  -o-transition: $ms;
  -webkit-transition: $ms;
  -moz-transition: $ms;
}

#popup-container{
  @include transition(500ms);
  position: fixed;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(26,82,78,.7);
  border-radius: 5px;

  padding: 20px 0 20px 0;

  z-index: 100;

  right: 25px;
  top: 25px;
  text-align: center;

  h5{
    margin: 5px;
    font-family: $robotoslab;
  }

  button{
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent;
    outline: none;
    border: none;
    color: $whiteish;
    cursor: pointer;
    @include transition(500ms);
    &:hover{
      transform: scale(1.3);
    }
  }

  .answer_1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    width: 90%;
    cursor: pointer;

    &:nth-of-type(1){
      margin-top: 20px;
    }

    h5{
      margin: 5px;
    }

    div{
      height: 15px;
      width: 15px;
      background: $whiteish;
      border-radius: 100%;
      border: 2.5px solid $bgcolor;
      justify-self: start;
      @include transition(500ms);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .selected_answer{
      &::after{
        content: "";
        height: 6px;
        width: 6px;
        border-radius: 100%;
        background: $bgcolor;
      }
    }
  }

  .rating{
    font-size: 1.8rem;
    color: $whiteish;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    margin-top: 30px;

    &>*{
      cursor: pointer;
      @include transition(250ms);
      &:hover{
        transform: scale(1.2);
        color: yellow;
      }
    }
  }
}