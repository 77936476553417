#anim-svg{
  @media screen and (max-width:800px) {

    height: 20vh;
    transform: scale(1.6);
    margin: 0;
  }
}


#about-me-anim{

  #bg,#navbar{
    transform: translateX(-100%) scale(0) rotateX(90deg);
    transform-origin: center;
    opacity: 0;
    animation: 1.5s bg-anim ease-in-out 250ms forwards;
  }

  #paragraph01,
  #paragraph02,
  #paragraph03,
  #paragraph04,
  #paragraph05,
  #paragraph06{
    transform: rotateY(90deg);
    transform-box: fill-box;
    opacity: 0;
    animation: 1s paragraph ease-in-out 1.95s forwards;
  }

  #me{
    opacity: 0;
    transform-box: fill-box;
    transform-origin: center;
    animation: 1s about-me-me-anim ease-in-out 2.25s forwards, 1s move-to-center ease-in-out 3.85s forwards;

  }

  #about-me-leave-page{
    animation: 1s leave-page ease-in-out 3.85s forwards;
  }
}


@keyframes bg-anim {
  0%{
    transform: translateX(-100%) scale(0) rotateX(90deg);
    opacity: 0;
  }
  100%{
    transform: translateX(0%) scale(1) rotateX(0deg);
    opacity: 1;
  }
}

@keyframes paragraph{
  0%{
    transform: rotateY(90deg);
    opacity: 0;
  }
  100%{
    transform: rotateY(0deg);
    opacity: 1;
  }
}

@keyframes about-me-me-anim {
  0%{
    opacity: 0;
    transform: scale(0);
  }
  75%{
    opacity: 1;
    transform: scale(1.3);
  }
  100%{
    opacity: 1;
    transform: scale(1.0);
  }
}

@keyframes leave-page {
  0%{
    transform: translateX(0%);
    opacity: 1;
  }
  100%{
    transform: translateX(80%);
    opacity: 0;
  }
}

@keyframes move-to-center {
  0%{
    transform: translateX(0%);
  }
  100%{
    transform: translateX(100%);
  }
}