@import "variables";

body,html,#root{
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding: 0;
    margin: 0;
}

#root{
   .subpage{
        transition: 500ms;
        -o-transition: 500ms;
        -webkit-transition: 500ms;
        -moz-transition: 500ms;
        opacity: 0;
    }
}
body{
    background: $bgcolor;
}
*{
    box-sizing: border-box;
}

h1,h2,h3,h4,h5,h6,p,a,span{

    color: $whiteish;
}

@mixin transition($ms){
    transition: $ms;
    -o-transition: $ms;
    -webkit-transition: $ms;
    -moz-transition: $ms;
}

.dynamic-container{
    text-align: center;
    height: 40vh;
    @include transition(500ms);
}


.svg-anim{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $whiteish;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $lightergreen;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $green;
}