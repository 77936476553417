@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@900&display=swap');

$bgcolor: #31B2A8;
$whiteish: #D8F0EE;
$grey: #6D7A79;
$green: #1a524e;
$lightergreen: #056D65;

$robotomono: 'Roboto Mono', monospace;
$robotoslab: 'Roboto Slab', serif;



