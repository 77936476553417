#projects-svg{
  @media screen and (max-width:800px) {

    height: 23vh;
    transform: scale(1.4);
    margin: 0;
  }
}

#projects-anim{
  #bg{
    animation: 1000ms popUp ease-in-out forwards;
    transform-origin: center;

  }

  #person_2,#person{
    transform-origin: center;
    animation: 1000ms popUp ease-in-out 500ms forwards;
    opacity: 0;
  }

  #table{
    transform-origin: center;
    animation: 1000ms popUp ease-in-out 800ms forwards;
    opacity: 0;
  }

  #left_circle_1{
    transform-origin: center;
    animation: 1000ms popUp ease-in-out 900ms forwards;
    opacity: 0;
  }

  #left_circle_2{
    transform-origin: center;
    animation: 1000ms popUp ease-in-out 1000ms forwards;
    opacity: 0;
  }

  #left_circle_3{
    transform-origin: center;
    animation: 1000ms popUp ease-in-out 1100ms forwards;
    opacity: 0;
  }

  #cup,
  #right_leaves{
    transform-origin: center;
    opacity: 0;
    animation: 1000ms come-from-right ease-in-out 900ms forwards;
  }

  #left_leaves{
    transform-origin: center;
    animation: 1000ms come-from-left ease-in-out 900ms forwards;
    opacity: 0;
  }

  #mountains,
  #mountain_wave{
    transform-origin: center;
    opacity: 0;
    animation: 1000ms come-from-right 1100ms forwards;
  }

  #moon,
  #lines{
    transform-origin: center;
    animation: 1000ms popUp 1100ms forwards;
    opacity: 0;
  }


  #right_square_1{
    transform-origin: center;
    animation: 1000ms popUp 1300ms forwards;
    opacity: 0;
  }

  #right_square_2{
    transform-origin: center;
    animation: 1000ms popUp 1500ms forwards;
    opacity: 0;
  }

  #right_square_3{
    transform-origin: center;
    animation: 1000ms popUp 1700ms forwards;
    opacity: 0;
  }

  #mouse{
    transform-origin: center;
    animation: 1000ms popUp 1900ms forwards;
    opacity: 0;
  }

  #left_squares{
    transform-origin: center;
    animation: 1000ms popUp 1900ms forwards;
    opacity: 0;
  }


  #monitor{
    transform-origin: center;
    animation: 1000ms popUp 2500ms forwards;
    opacity: 0;
  }

  #left_cloud{
    transform-origin: center;
    animation: 1000ms come-from-left 2800ms forwards;
    opacity: 0;
  }


  #right_cloud{
    transform-origin: center;
    transform-box: fill-box;
    animation: 1000ms come-from-right 3000ms forwards;
    opacity: 0;
  }

  #sun{
    transform-origin: center;
    transform-box: fill-box;
    animation: 1000ms come-from-left 3000ms forwards;
    opacity: 0;
  }





}

@keyframes come-from-left {
  from{
    opacity: 0;
    transform: translateX(-100%) scale(0);
  }
  to{
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

@keyframes come-from-right {
  from{
    opacity: 0;
    transform: translateX(100%) scale(0);
  }
  to{
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

@keyframes popUp{
  0%{
    opacity: 0;
    transform: scale(0);
  }
  75%{
    opacity: 1;
    transform: scale(1.3);
  }
  100%{
    opacity: 1;
    transform: scale(1.0);
  }
}