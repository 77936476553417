@import '../../variables';

.home-btn{
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  position: absolute;
  @media screen and (max-width:800px) {
    position: relative;
  }
  left: 5px;
  top: 5px;
}

#home-btn-house,
#home-btn-roof{
  transition: 500ms;
  -o-transition: 500ms;
  -webkit-transition: 500ms;
  -moz-transition: 500ms;
}

#home-btn-house{
  transform-origin: bottom;
}

#home-btn-roof{
  transform-origin: top;
}

.home-btn:hover #home-btn-house,
.home-btn:hover #home-btn-roof{
  transform: scale(.9);
}