@import '../../variables';

@mixin transition($ms){
  transition: $ms;
  -o-transition: $ms;
  -webkit-transition: $ms;
  -moz-transition: $ms;
}

@mixin flex-center($gap){
  display: flex;
  align-items: center;
  justify-content: center;
  gap:$gap;
}

#morph-container{
  @include flex-center(0)
}

.skills-container{
  @include flex-center(50px);
  flex-direction: column;
  padding: 0 50px 0 50px;

  margin-top: 50px;
  margin-bottom: 100px;




  &>*{
    width: 600px;
    @media screen and (max-width:800px) {
      width: 100%;
    }
  }

  &>*:nth-child(1){
    align-self: baseline;
    @include flex-center(0);
    flex-direction: column;
    h3{
      align-self: baseline;
      background: $whiteish;
      color: $green;
      padding: 5px 15px 5px 15px;
      border-radius: 5px;
      width: max-content;
    }

    div{
      background: $whiteish;
      padding: 5px 15px 5px 15px;
      border-radius: 5px;
      width: 100%;
      h4{
        color: $green;
      }
    }
  }

  &>*:nth-child(2){
    align-self: center;
    @include flex-center(0);
    flex-direction: column;
    h3{
      background: $green;
      color: $whiteish;
      padding: 5px 15px 5px 15px;
      border-radius: 5px;
      width: max-content;
    }

    div{
      background: $green;
      padding: 5px 15px 5px 15px;
      border-radius: 5px;
      width: 100%;
      h4{
        color: $whiteish;
      }
    }
  }

  &>*:nth-child(3){
    align-self: end;
    @include flex-center(0);
    flex-direction: column;
    h3{
      align-self: end;
      background: $whiteish;
      color: $green;
      padding: 5px 15px 5px 15px;
      border-radius: 5px;
      width: max-content;
    }

    div{
      background: $whiteish;
      padding: 5px 15px 5px 15px;
      border-radius: 5px;
      width: 100%;
      h4{
        color: $green;
      }
    }
  }

  h3,h4{
    font-family: $robotomono;
  }
}


#skills-cube{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -100;
  top: 0vh;
}