@import '../../variables';

@mixin transition($ms){
    transition: $ms;
    -o-transition: $ms;
    -webkit-transition: $ms;
    -moz-transition: $ms;
}

.main-content-container{
    display: flex;
    align-items: center;

    transform: translateY(-25%);
    justify-content: space-between;
    flex-wrap: wrap;

    &>.main-left-container{
        text-align: center;
        min-width: 500px;
        max-width: 50%;
        flex-grow: 1;

        cursor: default;


        @include transition(500ms);

        &>h1{
            font-size: 5rem;
            margin: 0;
            font-family: $robotoslab;
        }

        &>h6{
            font-size: .8rem;
            margin: 5px;
            font-family: $robotomono;
        }

        &>button{
            padding: 5px 50px 5px 50px;
            background: transparent;
            border: 1px solid $whiteish;
            outline: none;
            border-radius: 100px;
            color: $whiteish;
            cursor: pointer;
            margin-top: 100px;

            font-family: $robotomono;

            @include transition(500ms);
            &:hover{
                color: $green;
                background: $bgcolor;
            }
        }


        @media screen and (max-width:800px) {
            min-width: 0;
            max-width: none;
            width: 100vw;
            margin-top: 300px;
            &>h1 {
                font-size: 4rem;
            }
        }

    }

    &>.main-navbar{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        width: 30%;
        min-width: 300px;

        @include transition(500ms);



        &>button{
            position: relative;
            width: 100%;
            font-size: 3rem;
            font-family: $robotomono;

            clip-path: polygon(3% 0, 100% 0, 100% 100%, 0% 100%);
            color: $whiteish;
            background: transparent;

            outline: none;
            border: none;

            @include transition(500ms);

            cursor: pointer;
            overflow: hidden;
        }
        &>button::before{
            content:"";
            background: $lightergreen;
            left: 10px;
            width: 10px;
            height: 150%;
            transform: translateY(-25%) rotateZ(12deg);
            position: absolute;

        }
        &>button:hover{
            background: $lightergreen;
            color: $bgcolor;
            //background: linear-gradient(113deg,$lightergreen 0% 80%,transparent 80%);
        }

        @media screen and (max-width:800px) {
            //min-width: none;
            max-width: none;
            width: 100vw;
            margin-top: 100px;
            &>h1 {
                font-size: 4rem;
            }
        }
    }
}


#loading-anim{
    transform: translateY(100%);
    #load-1,
    #load-2{
        transform-origin: center;
        transform: rotateZ(0deg);
        animation: 2s rotate linear infinite;
    }
}

@keyframes rotate {
    from{
        transform: rotateZ(0deg);
    }
    to{
          transform: rotateZ(360deg);
      }

}
